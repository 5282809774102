// MARK: React
import * as React from "react";
import "./style.scss";

// MARK: Mobx
import {inject, observer} from "mobx-react";

// MARK: Resouces
import strings from "../../../resources/strings";

// MARK: Stores
import SurveyStore from "../../../stores/SurveyStore";

interface IStores {
	surveyStore: SurveyStore;
}

interface IProps extends IStores {
}

@inject("surveyStore")
@observer
export default class StepperFinish extends React.Component<IProps> {
	public static defaultProps: IStores;

	public render() {
		return (
			<div className="stepperNameContainer">
				<div className="stepperNameFormArea">
					<p className="stepperNameTitle">{strings.stepper.finish.title}</p>
				</div>
			</div>
		);
	}
}
