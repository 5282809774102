// MARK: React
import * as React from "react";
import "./style.scss";

// MARK: Api
import { Hotel } from "@startapp/mistral-user-api";

// MARK: Mobx
import { observer } from "mobx-react";

interface IProps {
	hotel: Hotel;
}

@observer
export default class HotelCard extends React.Component<IProps> {
	public render() {
		const { hotel } = this.props;
		return (
			<div className="hotelCardContainer">
				<div className="hotelCardImage">
					<div className="hotelCardImageContainer">
						<img
							// TODO: Change this for a image hotel placeholder
							src={hotel.image ? hotel.image.url : "/icon_512.png"}
							alt={hotel.name}
						/>
					</div>
				</div>
				<div className="hotelCardDetails">
					<h2 className="hotelCardTitle">
						{hotel.name}
					</h2>
					<h2 className="hotelCardDescription">
						<p>{hotel.description}</p>
					</h2>
				</div>
			</div>
		);
	}
}
