import { LocalizedStringsMethods } from "localized-strings";
import { UAParser } from "ua-parser-js";

let baseUrl: string = "localhost:8000";
let strings: (LocalizedStringsMethods & any) | null = null;

export function setUrl(url: string): void {
    baseUrl = url;
}

export function setStrings(newStrings: (LocalizedStringsMethods & {}) | null): void {
    strings = newStrings;
}
export interface LatLng {
    lat: number;
    lng: number;
}

export interface Image {
    thumb: SimpleImage;
    width: number;
    height: number;
    url: string;
}

export interface SimpleImage {
    width: number;
    height: number;
    url: string;
}

export interface File {
    name: string;
    url: string;
}

export interface Crop {
    x: number;
    y: number;
    width: number;
    height: number;
}

export interface UncertainImage {
    bytes: Buffer | null;
    image: Image | null;
    crop: Crop | null;
}

export interface UncertainFile {
    fileData: UploadFile | null;
    file: File | null;
}

export interface UploadFile {
    bytes: Buffer;
    name: string;
}

export interface Hotel {
    id: string;
    image: Image;
    nick: string;
    name: string;
    description: string;
}

export interface HotelDetails {
    nick: string;
    name: string;
    description: string;
}

export interface Survey {
    id: string;
    answerSections: AnswerSection[];
    createdAt: Date;
    name: string;
    email: string;
    phone: string;
    nps: number;
    comment: string;
    metaData: SurveyDetailsMetaData[];
}

export interface SurveyDetails {
    name: string;
    email: string;
    phone: string;
    nps: number;
    comment: string;
    metaData: SurveyDetailsMetaData[];
}

export interface SurveyDetailsMetaData {
    tag: string;
    value: string;
}

export interface AnswerSection {
    title: string;
    answers: Answer[];
}

export interface Answer {
    rate: number;
    comment: string;
    question: Question;
}

export interface NewAnswer {
    questionId: string;
    rate: number;
    comment: string;
}

export interface QuestionSection {
    id: string;
    title: string;
    questions: Question[];
}

export interface Question {
    id: string;
    title: string;
}

export interface NewSurvey {
    answers: NewAnswer[];
    name: string;
    email: string;
    phone: string;
    nps: number;
    comment: string;
    metaData: SurveyDetailsMetaData[];
}

export enum ImageFormat {
    png = "png",
    jpeg = "jpeg",
}

export function translateImageFormat(enumImageFormat: ImageFormat): string {
    switch (enumImageFormat) {
        case ImageFormat.png: {
            return strings ? strings["enum"]["ImageFormat"]["png"] || ImageFormat.png : ImageFormat.png;
        }
        case ImageFormat.jpeg: {
            return strings ? strings["enum"]["ImageFormat"]["jpeg"] || ImageFormat.jpeg : ImageFormat.jpeg;
        }
    }
    return "";
}

export function allValuesImageFormat(): ImageFormat[] {
    return [
        ImageFormat.png,
        ImageFormat.jpeg,
    ];
}

export function allTranslatedValuesImageFormat(): string[] {
    return [
        translateImageFormat(ImageFormat.png),
        translateImageFormat(ImageFormat.jpeg),
    ];
}

export function allDisplayableValuesImageFormat(): string[] {
    return allTranslatedValuesImageFormat().sort();
}

export function valueFromTranslationImageFormat(translation: string): ImageFormat {
    const index = allTranslatedValuesImageFormat().indexOf(translation);
    return allValuesImageFormat()[index] || ImageFormat.png;
}

export enum ErrorType {
    NotFound = "NotFound",
    InvalidArgument = "InvalidArgument",
    MissingArgument = "MissingArgument",
    WrongLoginOrPassword = "WrongLoginOrPassword",
    NotLoggedIn = "NotLoggedIn",
    ExpiredResetPasswordToken = "ExpiredResetPasswordToken",
    AccessNotAllowed = "AccessNotAllowed",
    EmailAlreadyInUse = "EmailAlreadyInUse",
    InvalidEmail = "InvalidEmail",
    NickAlreadyInUse = "NickAlreadyInUse",
    InvalidNick = "InvalidNick",
    Fatal = "Fatal",
    Connection = "Connection",
}

export function translateErrorType(enumErrorType: ErrorType): string {
    switch (enumErrorType) {
        case ErrorType.NotFound: {
            return strings ? strings["enum"]["ErrorType"]["NotFound"] || ErrorType.NotFound : ErrorType.NotFound;
        }
        case ErrorType.InvalidArgument: {
            return strings ? strings["enum"]["ErrorType"]["InvalidArgument"] || ErrorType.InvalidArgument : ErrorType.InvalidArgument;
        }
        case ErrorType.MissingArgument: {
            return strings ? strings["enum"]["ErrorType"]["MissingArgument"] || ErrorType.MissingArgument : ErrorType.MissingArgument;
        }
        case ErrorType.WrongLoginOrPassword: {
            return strings ? strings["enum"]["ErrorType"]["WrongLoginOrPassword"] || ErrorType.WrongLoginOrPassword : ErrorType.WrongLoginOrPassword;
        }
        case ErrorType.NotLoggedIn: {
            return strings ? strings["enum"]["ErrorType"]["NotLoggedIn"] || ErrorType.NotLoggedIn : ErrorType.NotLoggedIn;
        }
        case ErrorType.ExpiredResetPasswordToken: {
            return strings ? strings["enum"]["ErrorType"]["ExpiredResetPasswordToken"] || ErrorType.ExpiredResetPasswordToken : ErrorType.ExpiredResetPasswordToken;
        }
        case ErrorType.AccessNotAllowed: {
            return strings ? strings["enum"]["ErrorType"]["AccessNotAllowed"] || ErrorType.AccessNotAllowed : ErrorType.AccessNotAllowed;
        }
        case ErrorType.EmailAlreadyInUse: {
            return strings ? strings["enum"]["ErrorType"]["EmailAlreadyInUse"] || ErrorType.EmailAlreadyInUse : ErrorType.EmailAlreadyInUse;
        }
        case ErrorType.InvalidEmail: {
            return strings ? strings["enum"]["ErrorType"]["InvalidEmail"] || ErrorType.InvalidEmail : ErrorType.InvalidEmail;
        }
        case ErrorType.NickAlreadyInUse: {
            return strings ? strings["enum"]["ErrorType"]["NickAlreadyInUse"] || ErrorType.NickAlreadyInUse : ErrorType.NickAlreadyInUse;
        }
        case ErrorType.InvalidNick: {
            return strings ? strings["enum"]["ErrorType"]["InvalidNick"] || ErrorType.InvalidNick : ErrorType.InvalidNick;
        }
        case ErrorType.Fatal: {
            return strings ? strings["enum"]["ErrorType"]["Fatal"] || ErrorType.Fatal : ErrorType.Fatal;
        }
        case ErrorType.Connection: {
            return strings ? strings["enum"]["ErrorType"]["Connection"] || ErrorType.Connection : ErrorType.Connection;
        }
    }
    return "";
}

export function allValuesErrorType(): ErrorType[] {
    return [
        ErrorType.NotFound,
        ErrorType.InvalidArgument,
        ErrorType.MissingArgument,
        ErrorType.WrongLoginOrPassword,
        ErrorType.NotLoggedIn,
        ErrorType.ExpiredResetPasswordToken,
        ErrorType.AccessNotAllowed,
        ErrorType.EmailAlreadyInUse,
        ErrorType.InvalidEmail,
        ErrorType.NickAlreadyInUse,
        ErrorType.InvalidNick,
        ErrorType.Fatal,
        ErrorType.Connection,
    ];
}

export function allTranslatedValuesErrorType(): string[] {
    return [
        translateErrorType(ErrorType.NotFound),
        translateErrorType(ErrorType.InvalidArgument),
        translateErrorType(ErrorType.MissingArgument),
        translateErrorType(ErrorType.WrongLoginOrPassword),
        translateErrorType(ErrorType.NotLoggedIn),
        translateErrorType(ErrorType.ExpiredResetPasswordToken),
        translateErrorType(ErrorType.AccessNotAllowed),
        translateErrorType(ErrorType.EmailAlreadyInUse),
        translateErrorType(ErrorType.InvalidEmail),
        translateErrorType(ErrorType.NickAlreadyInUse),
        translateErrorType(ErrorType.InvalidNick),
        translateErrorType(ErrorType.Fatal),
        translateErrorType(ErrorType.Connection),
    ];
}

export function allDisplayableValuesErrorType(): string[] {
    return allTranslatedValuesErrorType().sort();
}

export function valueFromTranslationErrorType(translation: string): ErrorType {
    const index = allTranslatedValuesErrorType().indexOf(translation);
    return allValuesErrorType()[index] || ErrorType.NotFound;
}

export async function uploadImage(image: Buffer, format: ImageFormat | null, crop: Crop | null, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        image: image.toString("base64"),
        format: format === null || format === undefined ? null : format,
        crop: crop === null || crop === undefined ? null : {
            x: crop.x || 0,
            y: crop.y || 0,
            width: crop.width || 0,
            height: crop.height || 0,
        },
    };
    const ret = await makeRequest({name: "uploadImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function cropImage(src: string, crop: Crop, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        src: src,
        crop: {
            x: crop.x || 0,
            y: crop.y || 0,
            width: crop.width || 0,
            height: crop.height || 0,
        },
    };
    const ret = await makeRequest({name: "cropImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function uploadFile(file: UploadFile, progress?: (progress: number) => void): Promise<File> {
    const args = {
        file: {
            bytes: file.bytes.toString("base64"),
            name: file.name,
        },
    };
    const ret = await makeRequest({name: "uploadFile", args, progress});
    return {
        name: ret.name,
        url: ret.url,
    };
}

export async function getHotel(hotelIndentifier: string, progress?: (progress: number) => void): Promise<Hotel> {
    const args = {
        hotelIndentifier: hotelIndentifier,
    };
    const ret = await makeRequest({name: "getHotel", args, progress});
    return {
        id: ret.id,
        image: {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        nick: ret.nick,
        name: ret.name,
        description: ret.description,
    };
}

export async function getQuestionSections(progress?: (progress: number) => void): Promise<QuestionSection[]> {
    const ret = await makeRequest({name: "getQuestionSections", args: {}, progress});
    return ret.map((e: any) => ({
        id: e.id,
        title: e.title,
        questions: e.questions.map((e: any) => ({
            id: e.id,
            title: e.title,
        })),
    }));
}

export async function createSurvey(hotelIdentifier: string, newSurvey: NewSurvey, progress?: (progress: number) => void): Promise<Survey> {
    const args = {
        hotelIdentifier: hotelIdentifier,
        newSurvey: {
            answers: newSurvey.answers.map(e => ({
                questionId: e.questionId,
                rate: e.rate || 0,
                comment: e.comment,
            })),
            name: newSurvey.name,
            email: newSurvey.email,
            phone: newSurvey.phone,
            nps: newSurvey.nps || 0,
            comment: newSurvey.comment,
            metaData: newSurvey.metaData.map(e => ({
                tag: e.tag,
                value: e.value,
            })),
        },
    };
    const ret = await makeRequest({name: "createSurvey", args, progress});
    return {
        id: ret.id,
        answerSections: ret.answerSections.map((e: any) => ({
            title: e.title,
            answers: e.answers.map((e: any) => ({
                rate: e.rate || 0,
                comment: e.comment,
                question: {
                    id: e.question.id,
                    title: e.question.title,
                },
            })),
        })),
        createdAt: new Date(ret.createdAt + "Z"),
        name: ret.name,
        email: ret.email,
        phone: ret.phone,
        nps: ret.nps || 0,
        comment: ret.comment,
        metaData: ret.metaData.map((e: any) => ({
            tag: e.tag,
            value: e.value,
        })),
    };
}

export async function ping(progress?: (progress: number) => void): Promise<string> {
    const ret = await makeRequest({name: "ping", args: {}, progress});
    return ret;
}

export async function setPushToken(token: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        token: token,
    };
    await makeRequest({name: "setPushToken", args, progress});
    return undefined;
}

//////////////////////////////////////////////////////

let fallbackDeviceId: string | null = null;

function setDeviceId(deviceId: string): void {
    fallbackDeviceId = deviceId;
    try {
        localStorage.setItem("deviceId", deviceId);
    } catch (e) {}
}

function getDeviceId(): string | null {
    try {
        return localStorage.getItem("deviceId") || fallbackDeviceId;
    } catch (e) {}

    return fallbackDeviceId;
}

async function device(): Promise<any> {
    const parser = new UAParser();
    parser.setUA(navigator.userAgent);
    const agent = parser.getResult();
    const me = document.currentScript as HTMLScriptElement;
    const device: any = {
            type: "web",
            platform: {
                browser: agent.browser.name,
                browserVersion: agent.browser.version,
                os: agent.os.name,
                osVersion: agent.os.version,
            },
            screen: {
                width: screen.width,
                height: screen.height,
            },
            version: me ? me.src : "",
            language: navigator.language,
    };

    const deviceId = getDeviceId();
    if (deviceId)
        device.id = deviceId;

    return device;
}

function randomBytesHex(len: number): string {
    let hex = "";
    for (let i = 0; i < 2 * len; ++i) {
        hex += "0123456789abcdef"[Math.floor(Math.random() * 16)];
    }

    return hex;
}

export interface ListenerTypes {
    fail: (e: Error, name: string, args: any) => void;
    fatal: (e: Error, name: string, args: any) => void;
    success: (res: string, name: string, args: any) => void;
}

// tslint:disable-next-line: ban-types
type HookArray = Function[];
export type Listenables = keyof ListenerTypes;
export type ListenersDict = { [k in Listenables]: Array<ListenerTypes[k]> };

const listenersDict: ListenersDict = {
    fail: [],
    fatal: [],
    success: [],
};

export function addEventListener(listenable: Listenables, hook: ListenerTypes[typeof listenable]): void {
    const listeners: HookArray = listenersDict[listenable];
    listeners.push(hook);
}

export function removeEventListener(listenable: Listenables, hook: ListenerTypes[typeof listenable]): void {
    const listeners: HookArray = listenersDict[listenable];
    listenersDict[listenable] = listeners.filter((h) => h !== hook) as any;
}

async function makeRequest({name, args, progress}: {name: string, args: any, progress?: (progress: number) => void}): Promise<any> {
    const deviceData = await device();
    return new Promise<any>((resolve, reject) => {
        const req = new XMLHttpRequest();
        req.open(
            "POST",
            `${baseUrl.startsWith("http") || baseUrl.startsWith("localhost") ?
                "" :
                "https://"
            }${baseUrl}/${name}`,
        );

        const body = {
            id: randomBytesHex(8),
            device: deviceData,
            name: name,
            args: args,
        };

        function roughSizeOfObject(object: any): number {
            const objectList = [];
            const stack = [ object ];
            let bytes = 0;

            while (stack.length) {
                const value = stack.pop();
                if (typeof value === "boolean") {
                    bytes += 4;
                } else if (typeof value === "string") {
                    bytes += value.length * 2;
                } else if (typeof value === "number") {
                    bytes += 8;
                } else if (
                    typeof value === "object"
                    && objectList.indexOf(value) === -1
                ) {
                    objectList.push(value);
                    for (const i in value) {
                        stack.push(value[i]);
                    }
                }
            }

            return bytes;
        }

        req.upload.onprogress = (event: ProgressEvent) => {
            if (event.lengthComputable && progress) {
                progress(Math.ceil(((event.loaded) / event.total) * 100));
            }
        };

        req.onreadystatechange = () => {
            if (req.readyState !== 4) return;
            try {
                const response = JSON.parse(req.responseText);

                try {
                    setDeviceId(response.deviceId);

                    if (response.ok) {
                        resolve(response.result);
                        listenersDict["success"].forEach((hook) => hook(response.result, name, args));
                    } else {
                        reject(response.error);
                        listenersDict["fail"].forEach((hook) => hook(response.error, name, args));
                    }
                } catch (e) {
                    console.error(e);
                    reject({type: "Fatal", message: e.toString()});
                    listenersDict["fatal"].forEach((hook) => hook(e, name, args));
                }
            } catch (e) {
                console.error(e);
                reject({ type: "BadFormattedResponse", message: `Response couldn't be parsed as JSON (${req.responseText}):\n${e.toString()}` });
                listenersDict["fatal"].forEach((hook) => hook(e, name, args));
            }
        };

        req.send(JSON.stringify(body));
    });
}
