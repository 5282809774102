import LocalizedStrings from "localized-strings";

const strings = new LocalizedStrings({
	ptBR: {
		cancel: "Cancelar",
		confirm: "Confirmar",
		ok: "Ok",
		logo: {
			alt: "Logo Mistral",
			white: "/logo_horizontal_white.png",
		},
		welcome: "Bem vindo ao Mistral!",
		error: {
			default: "Houve um erro, tente novamente mais tarde.",
		},
		stepper: {
			button: {
				back: "Voltar",
				next: "Próximo",
				finish: "Finalizar",
			},
			contact: {
				title: "Se possível, deixe seu e-mal ou seu telefone para podermos entrar em contato! (opicional)",
				email: "E-mail",
				phone: "Telefone",
			},
			finish: {
				title: "Obrigado pelo seu tempo, sua avaliação será de grande importância para nós.",
				notFoundHotel: "Hotél não encontrado.",
			},
			name: {
				title: "Por favor, nos informe seu nome.",
				name: "Nome",
				nameError: "Ops, seu nome prescisa ser prenchido.",
			},
			survey: {
				criticalTitle: "Conte-nos o motivo da sua avaliação baixa para este tópico. (opicional)",
				nps: "De 1 a 10, quanto você recomendaria esta clínica para seus amigos?",
				commentTitle: "Queriamos ouvir um pouco mais sobre sua experiência! (opicional)",
				comment: "Comentário",
				surveyError: "Ops, precisamos que preencha toda a pesquisa para poder continuarmos",
			},
		},
	},
});

export default strings;
