import { inspect } from "util";

// Resourses
import strings from "../resources/strings";
import { ErrorType } from "@startapp/mistral-user-api";

export default function handleError(e: any): string {
	const apiError: { type?: ErrorType, message?: string } = e;

	if (apiError.message && apiError.type !== ErrorType.Connection && apiError.type !== ErrorType.Fatal) {
		return apiError.message;
	}

	// tslint:disable-next-line: no-console
	console.error(inspect(e));

	return strings.error.default;
}
