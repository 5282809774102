// MARK: React
import * as React from "react";
import "./style.scss";

// MARK: Mobx
import { observer } from "mobx-react";

// MARK: Components
import FilledButton from "../FilledButton";

// MARK: Stores
import { routerStore } from "../../stores/_rootStore";

interface IProps {
	hotelId: string;
}

@observer
export default class WelcomeCard extends React.Component<IProps> {
	public render() {
		return (
			<div className="welcomeCardContainer">
				<h2 className="welcomeCardHeader">
					Sua avaliação será muito importante para nós!
				</h2>
				<FilledButton
					onClick={() => routerStore.push(`/questions/${this.props.hotelId}`)}
				>
					Começar
				</FilledButton>
				<h3 className="hotelCardDuration">
					Essa dura apenas 30 segundos!
				</h3>
			</div>
		);
	}
}
