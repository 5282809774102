// MARK: React
import * as React from "react";
import "./style.scss";

// MARK: Mobx
import { observer } from "mobx-react";

// MARK: Resources
import strings from "../../resources/strings";

// MARK: Layout
import MainLayout from "../../layouts/MainLayout";

interface IProps {
}

@observer
export default class HomePage extends React.Component<IProps> {
	public render() {
		return (
			<MainLayout>
				<div className="homePageContainer">
					{strings.welcome}
				</div>
			</MainLayout>
		);
	}
}
