// MARK: React
import * as React from "react";
import ReactDOM from "react-dom";
import "./style.scss";

// MARK: Mobx
import { Provider } from "mobx-react";
import { rootStore } from "./stores/_rootStore";

// MARK: Routing
import { Router, Route, Switch } from "react-router-dom";
import * as history from "history";
import { syncHistoryWithStore } from "mobx-react-router";
const browserHistory = history.createBrowserHistory();
syncHistoryWithStore(browserHistory, rootStore.routerStore);

// MARK: Resources
import strings from "./resources/strings";

// MARK: API
import * as api from "@startapp/mistral-user-api";
api.setStrings(strings);

const productionUrl: string = "https://api.mistral.startapp.one/user";
const stagingUrl: string = "https://api.mistral.startapp.one/user-staging";
api.setUrl(process.env.NODE_ENV ?
	((): string => {
		switch (process.env.NODE_ENV) {
			case "production": {
				return productionUrl;
			}
			case "staging":
			default: {
				return stagingUrl;
			}
		}
	})() : stagingUrl,
);

// MARK: Pages
import HomePage from "./pages/HomePage";
import HotelPage from "./pages/HotelPage";
import SurveyPage from "./pages/SurveyPage";

ReactDOM.render(
	(
		<Provider {...rootStore}>
			<Router history={rootStore.routerStore.history}>
				<Switch>
					<Route exact={true} path="/" component={HomePage} />
					<Route exact={true} path="/questions/:hotelSlug" component={SurveyPage} />
					<Route path="/:hotelSlug" component={HotelPage} />
				</Switch>
			</Router>
		</Provider>
	),
	document.getElementById("app"),
);
