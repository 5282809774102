// MARK: Api
import * as api from "@startapp/mistral-user-api";

// MARK: Mobx
import { observable, action } from "mobx";

// MARK: Stores
import { uiStore } from "../_rootStore";

// MARK: Resources
import handleError from "../../resources/handleError";

export default class HotelStore {
	@observable public loading: boolean = false;

	@observable public selectedHotel: api.Hotel | null = null;

	@action
	public getHotel = async (hotelSlug: string) => {
		if (this.loading) {
			return;
		}

		this.loading = true;

		try {
			this.selectedHotel = await api.getHotel(hotelSlug);
		} catch (e) {
			uiStore.showSnackbarError(handleError(e));
		} finally {
			this.loading = false;
		}
	};
}
