// MARK: React
import * as React from "react";
import { match } from "react-router";
import "./style.scss";

// MARK: Mobx
import { observer, inject } from "mobx-react";

// MARK: Resources
import strings from "../../resources/strings";
import QueryString from "query-string";

// MARK: Stores
import { RouterStore } from "mobx-react-router";
import SurveyStore from "../../stores/SurveyStore";
import HotelStore from "../../stores/HotelStore";

// MARK: Layout
import MainLayout from "../../layouts/MainLayout";

// MARK: Components
import StepperName from "./StepperName";
import StepperContact from "./StepperContact";
import StepperSurvey from "./StepperSurvey";
import FilledButton from "../../components/FilledButton";
import StepperFinish from "./StepperFinish";
import { routerStore } from "../../stores/_rootStore";

interface IProps {
	match: match<{ hotelSlug: string }>;
	routerStore: RouterStore;
	hotelStore: HotelStore;
	surveyStore: SurveyStore;
}

@inject("routerStore", "hotelStore", "surveyStore")
@observer
export default class SurveyPage extends React.Component<IProps> {
	public async componentWillMount() {

		const { hotelStore, surveyStore } = this.props;
		const { hotelSlug } = this.props.match.params;
		await hotelStore.getHotel(hotelSlug);

		if (!hotelStore.selectedHotel) {
			routerStore.push("/");
		} else {
			await surveyStore.getFormQuestions();
		}
	}

	public render() {
		const { hotelStore, surveyStore } = this.props;

		const currentStepComponent = () => {
			const { step } = this.props.surveyStore;
			switch (step) {
				case 1: return <StepperName />;
				case 2: return <StepperSurvey groupedQuestions={surveyStore.groupedAnsweredQuestions} />;
				case 3: return <StepperContact />;
				case 4: return <StepperFinish />;
				default: return <div>PÁGINA DE ERRO</div>;
			}
		};

		return (
			<MainLayout>
				{hotelStore.selectedHotel &&
					<>
						{currentStepComponent()}
						{surveyStore.step < surveyStore.maxNumberOfSteps &&
							<div className="stepperButton">
								{surveyStore.step !== 1 ?
									<FilledButton onClick={surveyStore.decrementStep}>
										{strings.stepper.button.back}
									</FilledButton> :
									null
								}
								{surveyStore.step < (surveyStore.maxNumberOfSteps - 1) ?
									<FilledButton onClick={surveyStore.incrementStep}>
										{strings.stepper.button.next}
									</FilledButton> :
									<FilledButton onClick={surveyStore.sendSurvey}>
										{strings.stepper.button.finish}
									</FilledButton>
								}
							</div>
						}
					</>
				}
			</MainLayout>
		);
	}
}
