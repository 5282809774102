// MARK: React
import * as React from "react";
import "./style.scss";

// MARK: Mobx
import { inject, observer } from "mobx-react";
import InputText from "../../../components/InputText";

// MARK: Components
import { Star } from "@material-ui/icons";

// MARK: Stores
import SurveyStore, { IQuestionAnswerSection } from "../../../stores/SurveyStore";
import strings from "../../../resources/strings";

// MARK: Stores
interface IStores {
	surveyStore: SurveyStore;
}

interface IProps extends IStores {
	groupedQuestions: IQuestionAnswerSection[];
}

@inject("surveyStore")
@observer
export default class StepperSurvey extends React.Component<IProps> {
	public componentWillMount() {
		this.props.surveyStore.validateStepperComponent = false;
	}

	public static defaultProps: IStores;

	public render() {
		const { surveyStore, groupedQuestions } = this.props;
		return (
			<div className="stepperSurveyContainer">
				<div className="stepperSurveyFormArea">
					{
						groupedQuestions.map((groupedQuestion, groupIndex) => {
							return (
								<>
									<p className="stepperSurveySection">
										{groupedQuestion.title}
									</p>
									{groupedQuestion.questions.map((question, index) =>
										<>
											<p className="stepperSurveyTitle">
												{question.title}
											</p>
											<div className="starsSurvey">
												{new Array(5).fill(0).map((_, star) => {
													++star;
													return (
														<>
															<span
																role="button"
																onClick={() => surveyStore.onStarClick(question.id, star)}
																className="star"
																style={{
																	cursor: "pointer",
																	color: question.rate >= star ? "#FCE94F" : "",
																}}
															>
																<Star />
															</span>
														</>
													);
												})}
												{question.rate !== 0 && question.rate <= 3 &&
													<>
														<p className="criticalText">{strings.stepper.survey.criticalTitle}</p>
														<InputText
															label={strings.stepper.survey.comment}
															name="comment"
															value={surveyStore.groupedAnsweredQuestions[groupIndex].questions[index].comment}
															onChange={(e) => surveyStore.handleCommentQuestion(question.id, e.target.value)}
															disabled={surveyStore.loading}
															multiline={true}
															rows="2"
														/>
													</>
												}
											</div>
										</>,
									)
									}
								</>
							);
						})
					}
					<p className="stepperSurveyTitle">{strings.stepper.survey.nps}</p>
					<div className="npsArea">
						{new Array(10).fill(0).map((_, nps) => {
							++nps;
							return (
								<>
									<button
										role="button"
										className={`nps ${surveyStore.nps === nps ? "npsSelected" : ""}`}
										onClick={() => surveyStore.npsClick(nps)}
									>
										{nps}
									</button>
								</>
							);
						})}
					</div>
					<p className="stepperSurveyTitle">{strings.stepper.survey.commentTitle}</p>
					<InputText
						label={strings.stepper.survey.comment}
						name="comment"
						value={surveyStore.comment}
						onChange={surveyStore.handleValueChange}
						disabled={surveyStore.loading}
						multiline={true}
						rows="4"
					/>
				</div>
			</div>
		);
	}
}
