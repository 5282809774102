// MARK: Api
import * as api from "@startapp/mistral-user-api";

// MARK: Mobx
import { observable, action } from "mobx";

// MARK: Stores
import VariableChangeHandler from "../_helpers/VariableChangeHandler";
import { uiStore, hotelStore } from "../_rootStore";

// MARK: Resources
import handleError from "../../resources/handleError";
import strings from "../../resources/strings";

export interface IQuestionAnswerSection {
	questions: IQuestionAnswer[];
	title: string;
	id: string;
}

export interface IQuestionAnswer extends api.Question {
	comment: string;
	rate: number;
}

export default class SurveyStore extends VariableChangeHandler {
	@observable public loading: boolean = false;

	// StepperForms
	@observable public name: string = "";
	@observable public email: string = "";
	@observable public phone: string = "";

	@observable public groupedAnsweredQuestions: IQuestionAnswerSection[] = [];

	@observable public nps: number = 0;
	@observable public comment: string = "";
	@observable public metadata: api.SurveyDetailsMetaData[] = [];

	@observable public validateStepperComponent: boolean = true;
	@observable public step: number =  1;
	public maxNumberOfSteps: number = 4;

	@observable
	public incrementStep = () => {
		if (this.step === 1) {
			this.validateName();
		}
		if (this.step === 2) {
			this.validateSurvey();
		}
		if (this.step < this.maxNumberOfSteps && this.validateStepperComponent) {
			this.step++;
		}
	};

	@observable
	public decrementStep = () => {
		if (this.step > 1) {
			this.step--;
		}
	};

	@action
	public getFormQuestions = async () => {
		if (this.loading) {
			return;
		}

		this.loading = false;

		try {
			this.groupedAnsweredQuestions = (await api.getQuestionSections()).map((groupedQuestion) => {
				return {
					id: groupedQuestion.id,
					title: groupedQuestion.title,
					questions: groupedQuestion.questions.map((question) => {
						return {
							comment: "",
							rate: 0,
							id: question.id,
							title: question.title,
						};
					}),
				};
			});
		} catch (e) {
			uiStore.showErrorSnackbar(handleError(e));
		} finally {
			this.loading = false;
		}
	};

	@action
	public onStarClick = (questionId: string, star: number) => {
		this.groupedAnsweredQuestions = this.groupedAnsweredQuestions.map((groupedQuestion) => {
			return {
				id: groupedQuestion.id,
				title: groupedQuestion.title,
				questions: groupedQuestion.questions.map((question) => {
					if (question.id === questionId) {
						question.rate = star;
					}
					return question;
				}),
			};
		});
	};

	@action
	public npsClick = (nps: number) => {
		this.nps = nps;
	};

	@action
	public getMetadata = (metadata: object) => {
		for (const key of Object.keys(metadata)) {
			this.metadata = [
				...this.metadata,
				{
					tag: key,
					value: metadata[key],
				},
			];
		}
	};

	@action
	public handleCommentQuestion = (questionId: string, value: string) => {
		this.groupedAnsweredQuestions = this.groupedAnsweredQuestions.map((groupedQuestion) => {
			return {
				id: groupedQuestion.id,
				title: groupedQuestion.title,
				questions: groupedQuestion.questions.map((question) => {
					if (question.id === questionId) {
						question.comment = value;
					}
					return question;
				}),
			};
		});
	};

	@action
	public validateName = () => {
		this.validateStepperComponent = true;

		if (this.name === "") {
			uiStore.showSnackbar(strings.stepper.name.nameError);
			this.validateStepperComponent = false;
		}
	};

	@action
	public validateSurvey = () => {
		this.validateStepperComponent = true;
		this.groupedAnsweredQuestions.forEach((groupedQuestion) => {
			groupedQuestion.questions.forEach((question) => {
				if (question.rate === 0) {
					this.validateStepperComponent = false;
					return;
				}
			});
		});

		if (this.validateStepperComponent) {
			if (this.nps < 1) {
				this.validateStepperComponent = false;
			}
		}

		if (!this.validateStepperComponent) {
			uiStore.showSnackbar(strings.stepper.survey.surveyError);
		}
	};

	@action
	public sendSurvey = async () => {
		if (this.loading) {
			return;
		}

		if (!hotelStore.selectedHotel) {
			return uiStore.showSnackbar(strings.stepper.finish.notFoundHotel);
		}

		this.loading = true;

		const newSurvey: api.NewSurvey = {
			answers: this.groupedAnsweredQuestions.map((groupedQuestion) => {
				return groupedQuestion.questions.map((question) => {
					return {
						questionId: question.id,
						rate: question.rate,
						comment: question.comment,
					};
				});
			}).reduce((l, r) => l.concat(r), [] as api.NewAnswer[]),
			email: this.email,
			name: this.name,
			nps: this.nps,
			phone: this.phone,
			comment: this.comment,
			metaData: this.metadata,
		};

		try {
			await api.createSurvey(hotelStore.selectedHotel.nick, newSurvey);
			this.clean();
			this.incrementStep();
		} catch (e) {
			uiStore.showSnackbar(handleError(e));
		} finally {
			this.loading = false;
		}
	};

	@action
	public clean = () => {
		this.name = "";
		this.phone = "";
		this.comment = "";
		this.email = "";
	}
}
