// MARK: React
import * as React from "react";
import "./style.scss";

interface IProps {
	className?: string;
	disabled?: boolean;
	onClick?: () => void;
}

export default class Navbar extends React.Component<IProps> {
	public render() {
		return (
			<>
				<header className="toolbar">
					<nav>
						<div className="navbar-content">
							<h1>Mistral</h1>
						</div>
					</nav>
				</header>
				<div className="navbarPlaceholder" />
			</>
		);
	}
}
