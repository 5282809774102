// MARK: React
import * as React from "react";
import "./style.scss";

import { match } from "react-router";

// MARK: Mobx
import { observer, inject } from "mobx-react";

// MARK: Stores
import { RouterStore } from "mobx-react-router";
import HotelStore from "../../stores/HotelStore";

// MARK: Layout
import MainLayout from "../../layouts/MainLayout";

// MARK: Components
import HotelCard from "../../components/HotelCard";
import WelcomeCard from "../../components/WelcomeCard";

interface IProps {
	match: match<{ hotelSlug: string }>;
	routerStore: RouterStore;
	hotelStore: HotelStore;
}

@inject("routerStore", "hotelStore")
@observer
export default class HotelPage extends React.Component<IProps> {
	public async componentWillMount() {
		const { hotelStore, routerStore } = this.props;

		await hotelStore.getHotel(this.props.match.params.hotelSlug);

		if (!hotelStore.selectedHotel) {
			routerStore.push("/");
		}
	}

	public render() {
		const { hotelStore } = this.props;

		return (
			<MainLayout>
				{hotelStore.selectedHotel ?
					<div className="hotelPageContainer">
						<HotelCard hotel={hotelStore.selectedHotel} />
						<WelcomeCard hotelId={hotelStore.selectedHotel.nick}  />
					</div> :
					null
				}
			</MainLayout>
		);
	}
}
