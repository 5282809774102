import { RouterStore } from "mobx-react-router";
import UIStore from "./_UIStore";
import HotelStore from "./HotelStore";
import SurveyStore from "./SurveyStore";

export const routerStore = new RouterStore();
export const uiStore = new UIStore();
export const hotelStore = new HotelStore();
export const surveyStore = new SurveyStore();

export const rootStore = {
	routerStore,
	uiStore,
	hotelStore,
	surveyStore,
};
