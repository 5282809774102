// MARK: React
import * as React from "react";
import "./style.scss";

// MARK: Mobx
import {inject, observer} from "mobx-react";
import InputText from "../../../components/InputText";

import SurveyStore from "../../../stores/SurveyStore";
import strings from "../../../resources/strings";

// MARK: Stores
interface IStores {
	surveyStore: SurveyStore;
}

interface IProps extends IStores {
}

@inject("surveyStore")
@observer
export default class StepperName extends React.Component<IProps> {
	public static defaultProps: IStores;

	public render() {
		const { surveyStore } = this.props;
		return (
			<div className="stepperNameContainer">
				<div className="stepperNameFormArea">
					<h2 className="stepperNameTitle">{strings.stepper.name.title}</h2>
					<InputText
						label={strings.stepper.name.name}
						type="text"
						name="name"
						value={surveyStore.name}
						onChange={surveyStore.handleValueChange}
						disabled={surveyStore.loading}
					/>
				</div>
			</div>
		);
	}
}
